.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
    width: 62.56px;
    height: 16.02px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    color: #fff;
}
.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button  {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #FF4820;
    font-family:var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px; 
    border:0;
    outline: none;
    cursor: pointer;
 

}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);}
    .gpt3__navbar-menu_container p {
        margin: 1rem 0;
    }
    .gpt3__navbar-menu{
        display: none;
        position: relative;
        margin-left: 1rem;
    }
    .gpt3__navbar-menu svg{
        cursor:pointer
    }
    .gpt3__navbar-menu_container  {
    
        margin: 1rem 0;
    }
    .gpt3__navbar-menu_container-links-sign{
        display: none;
    }
    @media  screen and (max-width: 1050px){
        .gpt3__navbar-links_container{
            display: none;
        }
        .gpt3__navbar-menu{
            display: flex;
        }
    }
    @media screen  and (max-width:  700px){
        .gpt3__navbar-menu_container{
            top: 20px;
        
    }
}

    @media screen and (max-width: 550px){
        .gpt3__navbar{
            padding: 2rem 4rem;
        }   
    
    .gpt3__navbar{
        padding: 2rem ;
    }
    .gpt3__navbar-sign{
        display: none;
    }
    .gpt3__navbar-menu_container-links-sign{
        display:    block;
    }
    .gpt3__navbar-menu_container{
        top: 20px;
}
}

    
 