.footer__container{
    display: flex;
    color:white;
    background-color: var(--color-footer);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.footer__heading{
    width: 100%;  
    margin-bottom: 3rem;
    text-align: center;
}
.footer__heading h1{
    font-size: 62px;
    font-weight: 800;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04rem;
    font-family: var(--font-family);
}
.footer__button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:1rem;
    border: 1px solid white;
    margin-bottom: 10rem;
    cursor: pointer;
    }
.footer__button p{
        box-sizing: border-box;
        font-size: 18px;
        line-height: 21px;
        word-spacing: 2px;
        font-family: var(--font-family);
}
.gpt3__footer-links{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        text-align: left;
    }  
.gpt3__footer-links div{
        width:250px;
        margin: 1rem;
    }

.gpt3__footer-links_logo{
       display: flex;
       flex-direction: column; 
    }

.gpt3__footer-links_logo{
    width: 118px;
    margin-bottom: 1rem;
    height: 30px;
}

.gpt3__footer-links_logo img {
    width: 118px;
    height: 30px;

    margin-bottom: 1rem;
}

.gpt3__footer-links_logo p{
        font-size: 12px;
        line-height: 14px;
        font-family: var(--font-family);
        color: #fff;
    }
.gpt3__footer-links_div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
.gpt3__footer-links_div h4{
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 16px;
        color: #fff;
    }
.gpt3__footer-links_div p{
        font-family: var(--font-family);
        font-size: 14px;
        line-height: 16px;
        color: #fff;
        cursor: pointer;
        margin:0.5rem 0;

    }
.gpt__footer-copyrights{
    margin-top: 2rem;
    text-align: center;
    width:100%;
}
.gpt3__footer-copyrights p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #d12828;
}
@media screen and (max-width: 850px) {
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-links div {
        margin: 1rem 0;
    }

    .gpt3__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
