.gpt3__header{
    display: flex;
}
.gpt3__header-content{
    flex:1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 5rem;
    
} 
.gpt3__header-content h1{

    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}
.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}
    .gpt3__header-content_input {
        width: 100%;
        margin: 2rem 0 1rem;

        display: flex;
        flex-direction: row;
}

.gpt3__header-content_input input {

border-radius: 5px 0px 0px 5px;
background:var(--color-footer);
font-size: 20px;
font-weight: 400;
line-height: 27px;
font-family: var(--font-family);
width:100%;
min-height: 50px;
flex:2;
border: 2px solid var(--color-footer);
padding: 0 1rem;
outline: none;
color:#ffff
}
.gpt3__header-content_input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.gpt3__header-content_people{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}
.gpt3__header-content_people img{
    width: 181.79 px;
    height:38px;
}
.gpt3__header-content_people p{
    margin-left: 1rem;
    font-family: var(--font-family);
    color:#fff;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: 38px;
    margin: 0 0 0 1rem;
}
.gpt3__header-image{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

}
.gpt3__header-image img{
    width: 100%;
    height: 100%;
}
@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content_people {
        flex-direction: column;
    }

    .gpt3__header-content_people p {
        margin: 0;
    }

    .gpt3__header-content_input input,
    .gpt3__header-content_input button {
        font-size: 16px;
        line-height: 20px;
    }
    @media screen and (max-width: 490px) {
        .gpt3__header-content h1 {
            font-size: 36px;
            line-height: 48px;
        }
    
        .gpt3__header-content p {
            font-size: 14px;
            line-height: 24px;
        }
    
        .gpt3__header-content__input input,
        .gpt3__header-content__input button {
            font-size: 12px;
            line-height: 16px;
        }
    }
}




