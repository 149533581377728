.gpt3__possibility{
    display: flex;
    flex-direction: row;

} 
.possibility-image{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 2rem;
}
.possibility-image img {
    width: 100%;
    height: 100%;
}
.possibility__content{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-start;
}
.possibility__content h4{
    font-size: 16;
    font-weight: 500;
    font-family: var(--font-family);
    line-height: 30px;
    color: #71e5ff;
}
.possibility__content h4:last-child{
    color:#ff8a71
}
.possibility__content p{
    font-size: 16px;
    line-height: 30px;
    font-family: var(--font-family);
    color: var(--color-text);
    margin-bottom: 2rem;

}
.possibility__content h1{
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    margin: 1rem 0;
}
@media  screen and (max-width: 950px){
    .gpt3__possibility{
        flex-direction: column;
    }
    .possibility-image{
        margin: 1rem 0;
    }
    .possibility__content{
        margin-top: 2rem;
    }

    
}