*{
  box-sizing: border-box;
  padding:0;
  margin: 0;
  scroll-behavior: smooth;
}
body{
  background-color: var(--color-bg);
}
a{
  color:unset;
  text-decoration: none;
}
.gradient__bg{
                

  /* global 92%+ browsers support */
  background: radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);
}
.gradient__text{
  background: var(--gradient-text);
  background-clip:text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section-padding{
  padding: 4rem 6rem;
}
.section-margin{
  margin: 4rem 6rem;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-10-23 11:28:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


@media  screen and (max-width: 700px){
   .section-padding{
    padding:  4rem;

   }
   .section-margin{
    margin:  4rem;
    
   }
  }
   @media  screen and (max-width: 550px){
    .section-padding{
     padding:  4rem 2rem;
 
    }
    .section-margin{
     margin:  4rem 2rem;
     
    }
  
}
    